<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <b-form-group id="input-group-2" label="ปีที่" label-for="input-2">
                                <b-form-select v-model="yearSelected" :options="years"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ฉบับที่"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="issue" placeholder="กรอกฉบับที่"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="เลขหน้า"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="pageNumber" placeholder="กรอกเลขหน้า"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="วันที่เผยแพร่"
                                label-for="input-2">
                                <b-form-input :id="`type-date`" :type="`date`" v-model="publishDate"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(saveJournal())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import moment from "moment-timezone";
import journalService from "../../../../../services/journal.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            journalId: null,
            issue: null,
            pageNumber: null,
            publishDate: null,
            years: [],
            yearSelected: null,
            timeZone: "Asia/Bangkok",
            dateNow: new Date(),
            currentTime: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "วารสาร",
                    href: "#",
                },
                {
                    text: "ตารางเล่มวารสาร",
                    to: { name: "journals" },
                },
                {
                    text: "เพิ่มเล่มวารสาร",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getYears() {
            this.yearSelected = 1;
            for (let num = 1; num <= 100; num++) {
                this.years.push(num);
            }
        },
        getDateTime() {
            this.publishDate = moment(this.dateNow).tz(this.timeZone).add(543, 'y').format('yyyy-MM-DD');
            this.currentTime = "00:00:00";
            console.log(this.publishDate);
        },
        saveJournal: async function () {
            this.isLoading = true;
            if (this.isValidate()) {
                let newR = {
                    year: this.yearSelected,
                    issue: this.issue,
                    page_number: this.pageNumber,
                    publish_date: this.publishDate + " " + this.currentTime
                }
                await journalService.addJournal(newR);
                history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.issue) this.errors.push("Issue required.");
            if (!this.pageNumber) this.errors.push("Number of page required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getYears();
        this.getDateTime();
    }

};
</script>